@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');
*{
    font-family: "Nunito", sans-serif;
}
.Chewy{
    font-family: "Chewy", cursive;
}
.Nunito{
    font-family: "Nunito", sans-serif;
}
/* fonts & utility */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* animation */
.bluetext{
    color: #B0D4ED !important;
}
.hide{
    display: none;
    animation: slide-top .25s linear alternate forwards;
}
@keyframes slide-top {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-.5rem);
    }
  }
.show:hover .hide{
    display: block;
    animation: slide-top .25s linear alternate forwards;
}
.show:hover .info{
    animation: slide-top .25s linear alternate forwards;
}
.scrollbar {
    width: 25rem;
    margin: 0 auto;
    height: 100vh;
    overflow-y: auto;
    z-index: -1;
    position: relative;
}
.scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-library{
    display: none;
    -webkit-animation: to-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: to-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.show-library:hover .hide-library{
    display: block;
    -webkit-animation: to-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: to-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.show-library:hover .library-info{
    -webkit-animation: to-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: to-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes to-top {
    0% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
  @keyframes to-top {
    0% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }

.modalauth{
    -webkit-transform: translate3d(0,0,0);
}
